import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import logo from '../images/logo.svg'

const Heading = styled.h1`
  margin: 34px 0 6px;

  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
  color: #18191a;

  @media (max-width: 640px) {
    font-size: 34px;
  }
`
const Text = styled.p`
  font-size: 22px;
  font-weight: 400;
  line-height: 1.36;
  color: #18191a;
  @media (max-width: 640px) {
    font-size: 20px;
  }
`

const WebLink = styled.a`
  color: #1959db;
  text-decoration: none;
  &:hover,
  &:active,
  &:visited {
    text-decoration: underline;
  }
`

const HList = styled.ul`
  list-style: none;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  & > li {
    display: inline-block;
    margin: 0;
  }
  & > li:after {
    content: '·';
    padding: 0 16px;
  }
  & > li:last-child:after {
    display: none;
  }
  @media (max-width: 640px) {
    width: 100%;

    & > li {
      display: block;
      border-bottom: 1px solid #dde0e4;

      &:last-child {
        border-bottom: none;
      }
    }
    & > li:after {
      display: none;
    }
    & > li > a {
      display: block;
      padding: 16px 16px;
    }
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`pranjal`, `portfolio`, `design`]} />
    <img
      src={logo}
      width={115}
      height={115}
      style={{ marginTop: 'calc(5%)' }}
      alt="Pranjal's Portfolio"
    />
    <Heading>Pranjal Kumar Singh</Heading>
    <Text>Multidisciplinary Product Designer based in Mumbai</Text>
    <HList style={{ marginTop: 44 }}>
      <li>
        <WebLink target="_blank" href="https://dribbble.com/pranjalksingh">
          Dribbble
        </WebLink>
      </li>
      <li>
        <WebLink target="_blank" href="https://github.com/pranjaltech">
          Github
        </WebLink>
      </li>
      <li>
        <WebLink target="_blank" href="https://twitter.com/pranjalksingh">
          Twitter
        </WebLink>
      </li>
      {/*<li>
        <WebLink target="_blank" href="mailto:pranjaltech@gmail.com">
          Email
        </WebLink>
      </li>*/}
      <li>
        <WebLink target="_blank" href="https://soundcloud.com/pranjal-plays">
          Music
        </WebLink>
      </li>
    </HList>
  </Layout>
)

export default IndexPage
